var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("div", { staticClass: "steps-progressbar py-5" }, [
          _c(
            "ul",
            { staticClass: "workflow-wrapper" },
            _vm._l(_vm.steps, function(item, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: _vm.getClassSteps(index),
                  on: {
                    click: function($event) {
                      return _vm.setStep(index)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            0
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }